const NAME = "QUORA_PIXEL";
const CNameMapping = {
  [NAME]: NAME,
  "Quora Pixel": NAME,
  "Quora pixel": NAME,
  "QUORA PIXEL": NAME,
  QuoraPixel: NAME,
  Quorapixel: NAME,
  QUORAPIXEL: NAME,
  Quora_Pixel: NAME,
  quora_pixel: NAME,
  Quora: NAME,
};

export { NAME, CNameMapping };
