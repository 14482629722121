const NAME = "KEEN";
const CNameMapping = {
  [NAME]: NAME,
  Keen: NAME,
  "Keen.io": NAME,
  keen: NAME,
  "keen.io": NAME,
};

export { NAME, CNameMapping };
