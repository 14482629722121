const NAME = "MOUSEFLOW";
const CNameMapping = {
  [NAME]: NAME,
  Mouseflow: NAME,
  mouseflow: NAME,
  mouseFlow: NAME,
  MouseFlow: NAME,
  "Mouse flow": NAME,
  "mouse flow": NAME,
  "mouse Flow": NAME,
  "Mouse Flow": NAME,
};

export { NAME, CNameMapping };
