const NAME = "FULLSTORY";
const CNameMapping = {
  [NAME]: NAME,
  Fullstory: NAME,
  FullStory: NAME,
  "full Story": NAME,
  "Full Story": NAME,
  "Full story": NAME,
  "full story": NAME,
  fullstory: NAME,
};

export { NAME, CNameMapping };
