const NAME = "SHYNET";

const CNameMapping = {
  [NAME]: NAME,
  shynet: NAME,
  ShyNet: NAME,
  shyNet: NAME,
  Shynet: NAME,
  "shy net": NAME,
  "Shy Net": NAME,
  "shy Net": NAME,
  "Shy net": NAME,
};

export { NAME, CNameMapping };
