const NAME = "HOTJAR";
const CNameMapping = {
  [NAME]: NAME,
  Hotjar: NAME,
  hotjar: NAME,
  "Hot Jar": NAME,
  "hot jar": NAME,
};

export { NAME, CNameMapping };
