const NAME = "MOENGAGE";
const CNameMapping = {
  [NAME]: NAME,
  MoEngage: NAME,
  moengage: NAME,
  Moengage: NAME,
  "Mo Engage": NAME,
  "mo engage": NAME,
  "Mo engage": NAME,
};

export { NAME, CNameMapping };
