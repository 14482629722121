const NAME = "LINKEDIN_INSIGHT_TAG";
const CNameMapping = {
  [NAME]: NAME,
  "LinkedIn Insight Tag": NAME,
  "LinkedIn insight tag": NAME,
  "linkedIn insight tag": NAME,
  Linkedin_insight_tag: NAME,
  LinkedinInsighttag: NAME,
  LinkedinInsightTag: NAME,
  LinkedInInsightTag: NAME,
  Linkedininsighttag: NAME,
  LINKEDININSIGHTTAG: NAME,
  linkedininsighttag: NAME,
};

export { NAME, CNameMapping };
