const NAME = "YANDEX_METRICA";
const CNameMapping = {
  [NAME]: NAME,
  Yandexmetrica: NAME,
  yandexmetrica: NAME,
  yandexMetrica: NAME,
  YandexMetrica: NAME,
};

export { NAME, CNameMapping };
