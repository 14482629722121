const NAME = "ADOBE_ANALYTICS";
const CNameMapping = {
  "Adobe Analytics": NAME,
  ADOBEANALYTICS: NAME,
  "ADOBE ANALYTICS": NAME,
  [NAME]: NAME,
  AdobeAnalytics: NAME,
  adobeanalytics: NAME,
  "adobe analytics": NAME,
  "Adobe analytics": NAME,
  "adobe Analytics": NAME,
};

export { NAME, CNameMapping };
