const NAME = "BINGADS";
const CNameMapping = {
  [NAME]: NAME,
  BingAds: NAME,
  bingads: NAME,
  "Bing Ads": NAME,
  "Bing ads": NAME,
  "bing Ads": NAME,
  "bing ads": NAME,
};

export { NAME, CNameMapping };
