const NAME = "ADROLL";
const CNameMapping = {
  [NAME]: NAME,
  Adroll: NAME,
  "Ad roll": NAME,
  "ad roll": NAME,
  adroll: NAME,
};

export { NAME, CNameMapping };
