const NAME = "DCM_FLOODLIGHT";
const CNameMapping = {
  [NAME]: NAME,
  "DCM Floodlight": NAME,
  "dcm floodlight": NAME,
  "Dcm Floodlight": NAME,
  DCMFloodlight: NAME,
  dcmfloodlight: NAME,
  DcmFloodlight: NAME,
  dcm_floodlight: NAME,
  DCM_Floodlight: NAME,
};
const GTAG = "globalSiteTag";

export { NAME, CNameMapping, GTAG };
