const NAME = "SNAP_PIXEL";
const CNameMapping = {
  [NAME]: NAME,
  Snap_Pixel: NAME,
  SnapPixel: NAME,
  SNAPPIXEL: NAME,
  snappixel: NAME,
  "Snap Pixel": NAME,
  "SNAP PIXEL": NAME,
  "snap pixel": NAME,
};

export { NAME, CNameMapping };
