const NAME = "CHARTBEAT";
const CNameMapping = {
  [NAME]: NAME,
  Chartbeat: NAME,
  chartbeat: NAME,
  "Chart Beat": NAME,
  "chart beat": NAME,
};

export { NAME, CNameMapping };
