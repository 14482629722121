const NAME = "POST_AFFILIATE_PRO";
const CNameMapping = {
  [NAME]: NAME,
  PostAffiliatePro: NAME,
  Post_affiliate_pro: NAME,
  "Post Affiliate Pro": NAME,
  "Post affiliate pro": NAME,
  "post affiliate pro": NAME,
  postaffiliatepro: NAME,
  POSTAFFILIATEPRO: NAME,
};

export { NAME, CNameMapping };
