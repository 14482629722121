const NAME = "CUSTOMERIO";
const CNameMapping = {
  [NAME]: NAME,
  Customerio: NAME,
  "Customer.io": NAME,
  "CUSTOMER.IO": NAME,
  "customer.io": NAME,
};

export { NAME, CNameMapping };
