const NAME = "POSTHOG";
const CNameMapping = {
  [NAME]: NAME,
  PostHog: NAME,
  Posthog: NAME,
  posthog: NAME,
  "Post Hog": NAME,
  "Post hog": NAME,
  "post hog": NAME,
};

export { NAME, CNameMapping };
