const NAME = "HS";
const CNameMapping = {
  [NAME]: NAME,
  Hubspot: NAME,
  HUBSPOT: NAME,
  "hub spot": NAME,
  "Hub Spot": NAME,
  "Hub spot": NAME,
};

export { NAME, CNameMapping };
