const NAME = "GA4";
const CNameMapping = {
  [NAME]: NAME,
  "Google Analytics 4": NAME,
  "Google analytics 4": NAME,
  "google analytics 4": NAME,
  "Google Analytics4": NAME,
  "Google analytics4": NAME,
  "google analytics4": NAME,
  GoogleAnalytics4: NAME,
};

export { NAME, CNameMapping };
