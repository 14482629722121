const NAME = "SNAPENGAGE";
const CNameMapping = {
  [NAME]: NAME,
  SnapEngage: NAME,
  Snap_Engage: NAME,
  snapengage: NAME,
  "SNAP ENGAGE": NAME,
  "Snap Engage": NAME,
  "snap engage": NAME,
};

export { NAME, CNameMapping };
